<template>
  <div v-if="zeiten">
    <div id="list-protokolle" v-if="zeiten">
      <CTabs v-if="Object.keys(zeiten).length > 0">
        <CTab v-for="(mitarbeiter, index) in Object.keys(zeiten)" v-bind:key="index">
          <template slot="title">
            <strong>{{ getMitarbeiter(mitarbeiter).name }} ({{ calculateTitel(getMitarbeiter(mitarbeiter).titel) }})</strong> <br/>  (ist: {{ getSumForMitarbeiter(zeiten[mitarbeiter], mitarbeiter)}} Std., soll pro Woche: {{ getMitarbeiter(mitarbeiter).sollstunden }} Std.)
          </template>
          <CCol sm="12" class="text-right">
            <br/>
            Gesamt - Alle:
            <strong class="text-right">
              {{ getSum(zeiten)}} Stunden
            </strong> - {{ getMitarbeiter(mitarbeiter).name }}: {{ parseFloat(getSumForMitarbeiter(zeiten[mitarbeiter], mitarbeiter) / getSum(zeiten) * 100).toFixed(2) }}%
          </CCol>
          <hr/>
          <CCol sm="12" class="text-right">
            Gesamt {{ getMitarbeiter(mitarbeiter).name }}:
            <strong class="text-right">
              {{ getSumForMitarbeiter(zeiten[mitarbeiter], mitarbeiter)}}
              Stunden
            </strong>
          </CCol>
          <div v-for="(tag, prtindex) in Object.keys(zeiten[mitarbeiter])" v-bind:key="prtindex">
            <hr/>
            <CRow>
              <CCol sm="6" class="">
                {{ getMitarbeiter(mitarbeiter).name }} ({{zeiten[mitarbeiter][tag][0].datum|moment("DD.MM.YYYY")}}):
                <strong class="text-right">
                  <span>
                    {{ getSumForTag(zeiten[mitarbeiter][tag], mitarbeiter) }} Stunden
                  </span>
                </strong>
              </CCol>
              <CCol sm="6" class="text-right">
                <span v-if="(prtindex) >= 1" >
                   {{ getMitarbeiter(mitarbeiter).name }} ({{zeiten[mitarbeiter][Object.keys(zeiten[mitarbeiter])[prtindex-1]][0].datum|moment("DD.MM.YYYY")}}):
                <strong class="text-right">
                  <span>
                    {{ getSumForTag(zeiten[mitarbeiter][Object.keys(zeiten[mitarbeiter])[prtindex-1]], mitarbeiter) }} Stunden
                  </span>
                </strong>
                </span>
              </CCol>
            </CRow>
            <hr/>
            <div v-if="zeiten[mitarbeiter][tag]">
              <div v-for="(protokoll, count) in zeiten[mitarbeiter][tag]" v-bind:key="count">
                <Protokoll v-if="zeiten[mitarbeiter][tag][count].protokollid" :protokoll="zeiten[mitarbeiter][tag][count]" :mitarbeiter="parseInt(mitarbeiter)"/>
                <Tageskalender v-if="zeiten[mitarbeiter][tag][count].tageskalenderid" :tageskalender="zeiten[mitarbeiter][tag][count]"/>
                <Mitarbeiter v-if="false" protokoll="zeit"></Mitarbeiter>
              </div>
            </div>
            <hr v-if="(prtindex >= Object.keys(zeiten[mitarbeiter]).length-1)" />
            <CRow v-if="(prtindex >= Object.keys(zeiten[mitarbeiter]).length-1)">
              <CCol sm="6" class="">
                <CButton class="btn btn-success" @click="download()">Download CSV </CButton>
              </CCol>
              <CCol sm="6" class="text-right">
                <span v-if="(prtindex) >= 0">
                     {{ getMitarbeiter(mitarbeiter).name }} ({{zeiten[mitarbeiter][tag][0].datum|moment("DD.MM.YYYY")}}):
                <strong class="text-right">
                  <span>
                    {{ getSumForTag(zeiten[mitarbeiter][tag], mitarbeiter) }} Stunden
                  </span>
                </strong>
                </span>
              </CCol>
            </CRow>
            <hr/>
          </div>
          <CCol sm="12" class="text-right">
            Gesamt {{ getMitarbeiter(mitarbeiter).name }}:
            <strong class="text-right">
              {{ getSumForMitarbeiter(zeiten[mitarbeiter], mitarbeiter)}}
              Stunden
            </strong>
          </CCol>
          <hr/>
          <CCol sm="12" class="text-right">
            Gesamt - Alle:
            <strong class="text-right">
              {{ getSum(zeiten, mitarbeiter)}} Stunden
            </strong>
            {{ getMitarbeiter(mitarbeiter).name }}: {{ parseFloat(getSumForMitarbeiter(zeiten[mitarbeiter], mitarbeiter) / getSum(zeiten) * 100).toFixed(2) }}%
          </CCol>
        </CTab>
      </CTabs>
      <CCol v-else>
        <br/>
        <br/>
        <strong class="text-right">
          Keine Einträge zu diesem Filter gefunden.
        </strong>
        <br/>
        Versuche Sie es erneut mit anderen Filtern.
      </CCol>
    </div>
  </div>
</template>

<script>
import Protokoll from '@/views/Report/components/entries/Protokoll'
import Tageskalender from '@/views/Report/components/entries/Tageskalender'
import Mitarbeiter from '@/views/Report/components/entries/Mitarbeiter'
import Vue from 'vue'

export default {
  name: 'report-components-list-mitarbeiter',
  components: {
    Protokoll,
    Tageskalender,
    Mitarbeiter
  },
  props: {
    zeiten: {
    }
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  data () {
    return {
      statusTitel: [
        { value: '10', label: 'Helfer' },
        { value: '20', label: 'Programmierer' },
        { value: '30', label: 'Monteur' },
        { value: '40', label: 'Obermonteur' },
        { value: '50', label: 'Kundendiensttechniker' },
        { value: '60', label: 'Meister' },
        { value: '70', label: 'Systemtechniker' }
      ],
      mitarbeiterList: []
    }
  },
  mounted () {
    this.loadMitarbeiter()
  },
  methods: {
    loadMitarbeiter () {
      Vue.axios.get('/mitarbeiter/get')
        .then((response) => {
          this.mitarbeiterList = response.data
        })
    },
    calculateTitel: function (titel) {
      let result = titel
      Vue._.forEach(this.statusTitel, function (sTitel) {
        if (sTitel.value === titel) {
          result = sTitel.label
        }
      })
      return result
    },
    getMitarbeiter (id) {
      const result = this.mitarbeiterList.filter(mitarbeiter => {
        if (mitarbeiter.mitarbeiterid === parseInt(id)) {
          return true
        }
        return false
      })
      if (result[0]) {
        return result[0]
      }
      return { name: 'Unbekannt' }
    },
    getSumForMitarbeiterTag (tag) {
      var map = []
      return map
    },
    getSum (protokolle, mitarbeiterId = null) {
      var map = []
      var mitarbeiterList = []

      Object.keys(protokolle).forEach(function (key, index) {
        map[index] = protokolle[key]
        mitarbeiterList[index] = key
      })

      map = map.map((mitarbeiter, index) => {
        if (Array.isArray(mitarbeiter)) {
          return this.getSumForMitarbeiter(mitarbeiter, mitarbeiterList[index])
        } else {
          return parseFloat(mitarbeiter.dauer)
        }
      })
      return this.getSumOfArrays(map)
    },
    getSumForMitarbeiter (mitarbeiter, mitarbeiterId = null) {
      return Object.keys(mitarbeiter).map(tag => {
        if (Array.isArray(mitarbeiter[tag])) {
          return this.getSumForTag(mitarbeiter[tag], mitarbeiterId)
        } else {
          return parseFloat(mitarbeiter[tag].dauer)
        }
      }).reduce((partial, value) => { return parseFloat(partial) + parseFloat(value) })
    },
    getSumForTag (tag, mitarbeiterId = null) {
      return tag.map(protokoll => {
        if (protokoll.arbeitszeiten) {
          return this.getSumForProtokoll(protokoll, mitarbeiterId)
        }
        return this.getSumForTageskalender(protokoll, mitarbeiterId)
      }).reduce((partial, value) => { return parseFloat(partial) + parseFloat(value) })
    },
    getSumForProtokoll (protokoll, mitarbeiterId = null) {
      if (mitarbeiterId) {
        return protokoll.arbeitszeiten.filter(arbeitszeit => parseInt(arbeitszeit.mitarbeiter.mitarbeiterid) === parseInt(mitarbeiterId)).map(arbeitszeit => arbeitszeit.dauer).reduce((partial, value) => { return parseFloat(partial) + parseFloat(value) })
      }
      return protokoll.arbeitszeiten.map(arbeitszeit => arbeitszeit.dauer).reduce((partial, value) => { return parseFloat(partial) + parseFloat(value) })
    },
    getSumForTageskalender (tageskalender, mitarbeiterId = null) {
      return parseFloat(tageskalender.dauer)
    },
    getSumOfArrays (map) {
      map = map.flat(3)
      map = map.reduce(function (partialSum, a) {
        return parseFloat(partialSum) + parseFloat(a)
      })
      return map
    },
    download () {
      // Example usage:
      const csvData = 'Name,Age\nJohn Doe,30\nJane Doe,25' // Your CSV data as a string
      this.downloadCSV(csvData, 'example.csv')
    },
    downloadCSV (csvData, fileName) {
      // Convert the CSV data to a Blob
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob)

      // Create a temporary download link and click it to start the download
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link)

      // Simulate a click on the link
      link.click()

      // Clean up by removing the link and revoking the Blob URL
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }
}
</script>
