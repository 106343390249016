<template>
  <div id='list-protokolle' v-if='protokolle'>
    <br/>
    <CRow>
    <CCol v-if='Object.keys(protokolle).length > 0' sm='6' class='text-left'>
      <div>
        <CButton class='btn btn-sm btn-success' @click='download()'><CIcon name='cil-storage' /> Download Tabelle (.csv) </CButton>
      </div>
    </CCol>
    <CCol v-if='Object.keys(protokolle).length > 0' sm='6' class='text-right'>
      <strong class='text-right'>
        Gesamt <span v-if='Object.keys(protokolle).length-1 > 0'>({{protokolle[Object.keys(protokolle)[Object.keys(protokolle).length-1]][0].datum|moment('DD.MM.YYYY')}} - {{protokolle[Object.keys(protokolle)[0]][0].datum|moment('DD.MM.YYYY')}})</span>:
        {{ parseFloat(getSum(protokolle)).toFixed(2) }} Stunden
      </strong>
    </CCol>
      </CRow>
    <div v-for='(tag, index, count) in protokolle' v-bind:key='index'>
      <hr/>
      <CRow>
        <CCol sm='6' class=''>
          <strong class=''>
            Gesamt: ({{tag[0].datum|moment('DD.MM.YYYY')}}):
            <span v-if='Array.isArray(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }))'>
            {{
                parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }).reduce(function (partialSum3, c) { return parseFloat(partialSum3) + parseFloat(c); })).toFixed(2)
              }} Stunden
          </span>
            <span v-else>
            {{ parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  })).toFixed(2) }} Stunden
          </span>
          </strong>
        </CCol>
        <CCol sm='6' class='text-right'>
          <strong v-if='(count-1) >= 0' class='text-right'>
            Gesamt: ({{protokolle[Object.keys(protokolle)[count-1]][0].datum|moment('DD.MM.YYYY')}}):
            <span v-if='Array.isArray(protokolle[Object.keys(protokolle)[count-1]].map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }))'>
            {{
                parseFloat(protokolle[Object.keys(protokolle)[count-1]].map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }).reduce(function (partialSum3, c) { return parseFloat(partialSum3) + parseFloat(c); })).toFixed(2)
              }} Stunden
          </span>
            <span v-else>
            {{ parseFloat(protokolle[Object.keys(protokolle)[count-1]].map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  })).toFixed(2) }} Stunden
          </span>
          </strong>
        </CCol>
      </CRow>
      <hr/>
      <div v-for='(protokoll, prtindex) in tag' v-bind:key='prtindex'>
        <Protokoll :protokoll='protokoll'/>
      </div>
      <hr v-if='(count >= Object.keys(protokolle).length-1)' />
      <CRow v-if='(count >= Object.keys(protokolle).length-1)'>
        <CCol sm='6' class=''>
          <CButton class='btn btn-sm btn-success' @click='download()'><CIcon name='cil-storage' /> Download Tabelle (.csv) </CButton>
        </CCol>
        <CCol sm='6' class='text-right'>
          <strong class=''>
            Gesamt: ({{tag[0].datum|moment('DD.MM.YYYY')}}):
            <span v-if='Array.isArray(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }))'>
            {{
                parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  }).reduce(function (partialSum3, c) { return parseFloat(partialSum3) + parseFloat(c); })).toFixed(2)
              }} Stunden
          </span>
            <span v-else>
            {{ parseFloat(tag.map(protokoll => protokoll.arbeitszeiten.map(y => y.dauer)).reduce(function (partialSum, a) { return parseFloat(partialSum)  + parseFloat(a.reduce(function (partialSum2, b) { return parseFloat(partialSum2) + parseFloat(b); }))  })).toFixed(2) }} Stunden
          </span>
          </strong>
        </CCol>
      </CRow>
    </div>
    <CCol v-if='Object.keys(protokolle).length > 0' sm='12' class='text-right'>
      <strong class='text-right'>
        Gesamt <span v-if='Object.keys(protokolle).length-1 > 0'>({{protokolle[Object.keys(protokolle)[Object.keys(protokolle).length-1]][0].datum|moment('DD.MM.YYYY')}} - {{protokolle[Object.keys(protokolle)[0]][0].datum|moment('DD.MM.YYYY')}})</span>:
        {{ parseFloat(getSum(protokolle)).toFixed(2) }} Stunden
      </strong>
    </CCol>
    <CCol v-else>
      <br/>
      <strong class='text-right'>
        Keine Einträge zu diesem Filter gefunden.
      </strong>
      <br/>
      Versuche Sie es erneut mit anderen Filtern.
    </CCol>
  </div>
</template>

<script>
import Protokoll from '@/views/Report/components/entries/Protokoll'
import moment from 'moment'

export default {
  name: 'report-components-list-protokolle',
  components: {
    Protokoll
  },
  props: {
    protokolle: {
      type: [Array, Object],
      required: true
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    getSum (protokolle) {
      var map = []

      Object.keys(protokolle).forEach(function (key, index) { map[index] = protokolle[key] })
      map = map.map(protokoll => {
        if (Array.isArray(protokoll)) {
          return protokoll.map(mitarbeiter => {
            if (Array.isArray(mitarbeiter.arbeitszeiten)) {
              return mitarbeiter.arbeitszeiten.map(protokoll2 => protokoll2.dauer)
            } else {
              return mitarbeiter
            }
          })
        } else {
          return false
        }
      })
      return this.getSumOfArrays(map)
    },
    getSumOfArrays (map) {
      if (Array.isArray(map) && map.length > 0) {
        map = map.flat(3)
        if (Array.isArray(map) && map.length > 0) {
          map = map.reduce(function (partialSum, a) {
            return parseFloat(partialSum) + parseFloat(a)
          })
        }
      }
      return map
    },
    download () {
      // Example usage:
      const csvData = 'Datum, Protokollnummer, Rechnungsnummer, Kunde, Projekt, Leistung, Arbeitsbeschreibung, Mitarbeiter, Zeit, Abgeschlossen, Schriftlich, Berechnet \n' // Your CSV data as a string
      const csvDataTest = this.getData()
      this.downloadCSV(csvData + csvDataTest, 'report-protokolle.csv')
    },
    downloadCSV (csvData, fileName) {
      // Convert the CSV data to a Blob
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob)

      // Create a temporary download link and click it to start the download
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link)

      // Simulate a click on the link
      link.click()

      // Clean up by removing the link and revoking the Blob URL
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    jsonToCSV (json) {
      const rows = [] // Array to hold the rows of the CSV file
      // Extract the array of objects
      Object.keys(json).forEach(key => {
        const data = json[key]
        // Define CSV headers based on the main object keys + some nested keys
        const headers = [
          'datum',
          'protokollid',
          'rechnungsnummer',
          'kunde_name1',
          'projekt_name',
          'leistung_name',
          'arbeitsbeschreibung',
          // Including some nested properties for demonstration
          'erste_arbeitszeit-mitarbeiter',
          'erste_arbeitszeit-dauer',
          'abgeschlossen',
          'schriftlich',
          'berechnet'
        ]
        // Iterate over each object to transform data into a CSV row
        data.forEach(item => {
          const row = headers.map(header => {
            if (header.includes('_')) {
              // Handle nested properties
              const parts = header.split('_')
              const parent = parts[0]
              const child = parts[1]
              if (parent === 'erste' && child === 'arbeitszeit-dauer') {
                // Special case for the first item in the arbeitszeiten array
                return item.arbeitszeiten && item.arbeitszeiten[0] ? item.arbeitszeiten[0].dauer : ''
              }
              if (parent === 'erste' && child === 'arbeitszeit-mitarbeiter') {
                // Special case for the first item in the arbeitszeiten array
                return item.arbeitszeiten && item.arbeitszeiten[0] ? item.arbeitszeiten[0].mitarbeiter.name : ''
              } else if (item[parent] && item[parent][child] !== undefined) {
                return `"${item[parent][child]}"`
              } else {
                return '-'
              }
            } else {
              if (header === 'datum') {
                return moment(item[header]).format('DD.MM.YYYY')
              }
              // Direct properties
              return (item[header] === true ? 'ja' : item[header] === false ? 'nein' : `"${item[header]}"`)
            }
          }).join(',')
          rows.push(row)
        })
      })
      // Combine all rows into a single string
      return rows.join('\n')
    },
    getData () {
      const data = this.jsonToCSV(this.protokolle)
      return data
    }
  }
}
</script>
