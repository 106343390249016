<template>
  <div id="protokoll-bilder">
    <div v-if="hasBilder() && this.arbeitszeiten">
      <div class="protokoll-bilder-arbeitszeit" v-for="(arbeitszeit, index) in arbeitszeiten" v-bind:key="'arbt' + index">
        <div v-if="arbeitszeit.bilder.length > 0">
          <h5>
            Aufgenommen am {{arbeitszeit.datum|moment("DD.MM.YYYY")}} durch {{arbeitszeit.mitarbeiter.name}}
          </h5>
          <CRow class="rowthumbs mb-3">
            <CCol sm="2" class="protokoll-bilder-arbeitszeit-bild" v-for="(bild, index2) in arbeitszeit.bilder" v-bind:key="'bld' + index2">
              <Bild :bild="bild" type="protokoll"/>
            </CCol>
          </CRow>
        </div>
      </div>
    </div>
    <div v-else-if="hasBilder() && this.tageskalender">
      <div v-if="tageskalender.bilder.length > 0">
        <h5>
          Aufgenommen am {{tageskalender.datum|moment("DD.MM.YYYY")}} durch {{tageskalender.mitarbeiter.name}}
        </h5>
        <CRow class="rowthumbs mb-3">
          <CCol sm="2" class="protokoll-bilder-arbeitszeit-bild" v-for="(bild, index2) in tageskalender.bilder" v-bind:key="'bld' + index2">
            <Bild :bild="bild" type="protokoll"/>
          </CCol>
        </CRow>
      </div>
    </div>
    <div v-else>
      <h4>Keine Bilder für dieses Protokoll hinterlegt.</h4>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Bild from '@/components/Bild'

export default {
  name: 'report-bilder',
  components: {
    Bild
  },
  props: {
    protokollid: [Number, String],
    tageskalenderid: [Number, String]
  },
  data () {
    return {
      arbeitszeiten: '',
      tageskalender: ''
    }
  },
  mounted () {
    this.loadArbeitszeiten()
    this.loadTageskalender()
  },
  watch: {
    protokollid: function () {
      this.loadArbeitszeiten()
    },
    tageskalenderid: function () {
      this.loadTageskalender()
    }
  },
  methods: {
    hasBilder () {
      let result = false
      if (this.arbeitszeiten) {
        Vue._.forEach(this.arbeitszeiten, function (protokoll) {
          if (protokoll.bilder.length > 0) {
            result = true
          }
        })
      } else {
        if (this.tageskalender) {
          if (this.tageskalender.bilder.length > 0) {
            result = true
          }
        }
      }
      return result
    },
    loadArbeitszeiten () {
      if (this.protokollid) {
        Vue.axios.get('/arbeitszeit/get/' + this.protokollid)
          .then((response) => {
            this.arbeitszeiten = response.data
          })
      }
    },
    loadTageskalender () {
      if (this.tageskalenderid) {
        Vue.axios.get('/tageskalender/details/' + this.tageskalenderid)
          .then((response) => {
            this.tageskalender = response.data
          })
      }
    }
  }
}
</script>
