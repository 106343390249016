<template>
  <div id="list-tageskalender" v-if="tageskalender">
    <br/>
    <CRow>
      <CCol  v-if="Object.keys(tageskalender).length > 0" sm="6" class="text-left">
        <div>
          <CButton class="btn btn-sm btn-success" @click="download()"><CIcon name="cil-storage" /> Download Tabelle (.csv) </CButton>
        </div>
      </CCol>
      <CCol  v-if="Object.keys(tageskalender).length > 0" sm="12" class="text-right">
      <strong class="text-right">
        Gesamt <span v-if="Object.keys(tageskalender).length-1 > 0">({{tageskalender[Object.keys(tageskalender)[Object.keys(tageskalender).length-1]][0].datum|moment("DD.MM.YYYY")}} - {{tageskalender[Object.keys(tageskalender)[0]][0].datum|moment("DD.MM.YYYY")}})</span>:
        <span>
          {{ getSum(tageskalender) }} Stunden
        </span>
      </strong>
      </CCol>
      </CRow>
    <div v-for="(tag, index, count) in tageskalender" v-bind:key="index">
      <hr/>
      <CRow>
        <CCol sm="6" class="">
          <strong class="">
            Gesamt ({{tag[0].datum|moment("DD.MM.YYYY")}}):
            <span v-if="true">
          {{ getSum(tag) }} Stunden
          </span>
            <span v-else>
          </span>
          </strong>
        </CCol>
        <CCol sm="6" class="text-right">
          <strong v-if="(count-1) >= 0" class="">
            Gesamt ({{ Object.keys(tageskalender)[(count-1)] }}):
            <span class="text-right">
              {{ getSum(tageskalender[Object.keys(tageskalender)[count-1]]) }} Stunden
            </span>
          </strong>
        </CCol>
      </CRow>
      <hr/>
      <div v-for="(tkalender, tkindex) in tag" v-bind:key="tkindex">
        <Tageskalender :tageskalender="tkalender"/>
      </div>
      <hr  v-if="(count >= Object.keys(tageskalender).length-1)" />
      <CRow v-if="(count >= Object.keys(tageskalender).length-1)">
        <CCol sm="6" class="">
          <CButton class="btn btn-success" @click="download()">Download CSV </CButton>
        </CCol>
        <CCol sm="6" class="text-right">
          <strong v-if="(count-1) >= 0" class="">
            Gesamt: ({{ Object.keys(tageskalender)[(count-1)] }}):
            <span v-if="true">
          {{ getSum(tageskalender[Object.keys(tageskalender)[count-1]]) }} Stunden
          </span>
            <span v-else>
          </span>
          </strong>
        </CCol>
      </CRow>
    </div>
    <hr v-if="Object.keys(tageskalender).length > 0"/>
    <CCol v-if="Object.keys(tageskalender).length > 0" sm="12" class="text-right">
      <strong class="text-right">
        Gesamt <span v-if="Object.keys(tageskalender).length-1 > 0">({{tageskalender[Object.keys(tageskalender)[Object.keys(tageskalender).length-1]][0].datum|moment("DD.MM.YYYY")}} - {{tageskalender[Object.keys(tageskalender)[0]][0].datum|moment("DD.MM.YYYY")}})</span>:
        <span>
        {{ getSum(tageskalender) }} Stunden
      </span>
      </strong>
    </CCol>
    <CCol v-else>
      <br/>
      <strong class="text-right">
        Keine Einträge zu diesem Filter gefunden.
      </strong>
      <br/>
      Versuche Sie es erneut mit anderen Filtern.
    </CCol>
  </div>
</template>

<script>
import Tageskalender from '@/views/Report/components/entries/Tageskalender'
import moment from 'moment/moment'

export default {
  name: 'report-components-list-tageskalender',
  components: {
    Tageskalender
  },
  props: {
    tageskalender: {
      type: [Array, Object],
      required: true
    }
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
    getSum (tageskalender) {
      var map = []

      Object.keys(tageskalender).forEach(function (key, index) { map[index] = tageskalender[key] })
      map = map.map(tageskalenderEntry => {
        if (Array.isArray(tageskalenderEntry)) {
          return this.getSum(tageskalenderEntry)
        } else {
          return tageskalenderEntry.dauer
        }
      })

      return this.getSumOfArrays(map)
    },
    getSumOfArrays (map) {
      if (Array.isArray(map) && map.length > 0) {
        map = map.flat(3)
        if (Array.isArray(map) && map.length > 0) {
          map = map.reduce(function (partialSum, a) {
            return parseFloat(partialSum) + parseFloat(a)
          })
        }
      }
      return map
    },
    download () {
      // Example usage:
      const csvData = 'Datum, TageskalenderID, Kunde, Projekt, Tätigkeit, Mitarbeiter, Dauer, zukünftig berechnen \n' // Your CSV data as a string
      const csvDataTest = this.getData()
      this.downloadCSV(csvData + csvDataTest, 'report-tageskalender.csv')
    },
    downloadCSV (csvData, fileName) {
      // Convert the CSV data to a Blob
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob)

      // Create a temporary download link and click it to start the download
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', fileName)

      // Append the link to the body (required for Firefox)
      document.body.appendChild(link)

      // Simulate a click on the link
      link.click()

      // Clean up by removing the link and revoking the Blob URL
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    },
    jsonToCSV (json) {
      const rows = [] // Array to hold the rows of the CSV file
      // Extract the array of objects
      Object.keys(json).forEach(key => {
        const data = json[key]

        // Define CSV headers based on the main object keys + some nested keys
        const headers = [
          'datum',
          'tageskalenderid',
          'kunde_name1',
          'projekt_name',
          'taetigkeit',
          'mitarbeiter_name',
          'dauer',
          'zukuenftigBerechnen'
        ]
        // Iterate over each object to transform data into a CSV row
        data.forEach(item => {
          const row = headers.map(header => {
            if (header.includes('_')) {
              // Handle nested properties
              const parts = header.split('_')
              const parent = parts[0]
              const child = parts[1]

              if (item[parent] && item[parent][child] !== undefined) {
                return `"${item[parent][child]}"`
              } else {
                return '-'
              }
            } else {
              if (header === 'datum') {
                return moment(item[header]).format('DD.MM.YYYY')
              }
              // Direct properties
              return (item[header] === true ? 'ja' : item[header] === false ? 'nein' : `"${item[header]}"`)
            }
          }).join(',')

          rows.push(row)
        })
      })

      // Combine all rows into a single string
      return rows.join('\n')
    },
    getData () {
      const data = this.jsonToCSV(this.tageskalender)
      return data
    },
    flatArray (array, number) {
      return array.flat(number)
    }
  }
}
</script>
