<template>
    <div>
        <ReportFilter v-model="reports"/>

        <CCard class="mt-1" v-if="reports">
          <CCardHeader>
            <CIcon name="cil-filter"/>
            Gefilterte Einträge
          </CCardHeader>
          <CCardBody>
            <CTabs>
              <CTab>
                <template slot="title">
                  Protokolle
                </template>
                <Protokolle v-if="reports.protokolle" :protokolle="reports.protokolle"/>
              </CTab>
              <CTab>
                <template slot="title">
                  Tageskalender
                </template>
                <Tageskalender v-if="reports.tageskalender" :tageskalender="reports.tageskalender"/>
              </CTab>
              <CTab v-if="user.roles.includes('ROLE_ADMIN') || user.roles.includes('ROLE_REPORT')">
                <template slot="title">
                  Mitarbeiter
                </template>
                <div v-if="reports.mitarbeiterZeiten">
                  <Mitarbeiter v-if="reports.mitarbeiterZeiten" :zeiten="reports.mitarbeiterZeiten"/>
                </div>
              </CTab>
            </CTabs>
          </CCardBody>
        </CCard>
    </div>
</template>

<script>
import ReportFilter from '@/views/Report/components/ReportFilter'
import Protokolle from '@/views/Report/components/lists/Protokolle'
import Tageskalender from '@/views/Report/components/lists/Tageskalender'
import Mitarbeiter from '@/views/Report/components/lists/Mitarbeiter'

export default {
  name: 'report-index',
  components: {
    ReportFilter,
    Protokolle,
    Tageskalender,
    Mitarbeiter
  },
  props: {
  },
  computed: {
    user () {
      return this.$store.getters['security/user']
    }
  },
  data () {
    return {
      reports: {}
    }
  },
  mounted () {
  }
}
</script>
